import React, { Component } from 'react';
import '../Css/Shape.css';
import '../Css/Style.css';
import { Link } from "react-router-dom";

export default class AccomWish extends Component {
    render() {
        return (
          
               
                <span ><Link to = '/successStory' className ='acm-wsh br-sft'>Accomplished Wishes</Link></span>
                       
                //     </div>

                //     <div></div>
                // </div>
           
        )
    }
}
