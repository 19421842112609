import styled from 'styled-components'; 

 
                export const SearchBarButton = styled.div` 
                overflowY: scroll; 
                height: 2500px; 
             `;



                export const StartWriting = styled.div` 
                overflowY: scroll; 
                height: 2500px; 
             `;

                export const StartWritingButton = styled.div` 
                overflowY: scroll; 
                height: 2500px; 
             `;


export const PageHeader = styled.div` 
   overflowY: scroll; 
   height: 2500px; 
`;  

export const AllPost = styled.div` 
   overflowY: scroll; 
   height: 2500px; 
`;  



export const Button = styled.div` 
   position: fixed;  
   width: 100%; 
   left: 80%; 
   top:60px;
   height: 20px; 
   font-size: 2.2rem; 
   z-index: -1.1; 
   cursor: pointer; 
   color: white; 
`

// export const btnsm = styled.div` 
//    position: fixed;  
//    width: 100%; 
//    left: 80%; 
//    top:60px;
//    height: 20px; 
//    font-size: 2.2rem; 
//    z-index: -1.1; 
//    cursor: pointer; 
//    color: white; 
// `

