import React from 'react'
// import "../App.css";
import '../Css/Shape.css';
import '../Css/Style.css';

export const StartWriting = () => {

    return (

        <div id="mn-cont" className="mn-cont">
            <div id="srt-wrt-asd1" className="srt-wrt">  </div>



            <div id="srt-wrt-sec" className="srt-wrt srt-wrt-sec">

                <div className="heading " > Let Your Wishes Echo
                </div>
                <div className="cp">No Login Required | Write Anonymously | No Societal Judgement</div>
                



                {/* <div className=" btn-sft"> <Link to="/Create">{<button className="btn-sm " type="submit" >Start Writing</button>}</Link> </div> */}

 

            </div>



            <div id="srt-wrt-asd2" className="srt-wrt"> </div>





        </div>

    )

}




// btn-success